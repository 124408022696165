<template>

<!--机构端 校园筛查 筛查计划管理 筛查计划查询  修改-->
  <div class="page" v-loading="loading">
    <div class="form-main">
      <el-form label-width="140px" :model="ruleForm" :rules="rules" ref="ruleForm" size="mini" class="account-ruleForm el-form">
        <el-form-item label="筛查计划名称" prop="screeningName">
          <el-input  v-model="ruleForm.screeningName" maxlength="30" placeholder="请填写计划名称"></el-input>
        </el-form-item>
        <el-form-item v-if="roleType === 9" label="学校" prop="greadSchoolClassSelect">
          <el-cascader :options='options' :props="props" v-model="ruleForm.greadSchoolClassSelect" collapse-tags></el-cascader>
        </el-form-item>
        <el-form-item v-if="roleType === 10" label="学校年级班级" prop="greadSchoolClassSelect">
          <el-cascader :options="options" :props="getGradeClass" v-model="ruleForm.greadSchoolClassSelect" @change="getDreadClassData" collapse-tags></el-cascader>
        </el-form-item>
        <el-form-item label="筛查员" prop="sysList">
          <el-select
          multiple
          collapse-tags
          filterable
          v-model="ruleForm.sysList"
          clearable
          placeholder="请选择筛查员">
            <el-option
              v-for="item in teacherListData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            <span style="float: left">{{ item.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.phone }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-date-picker
            disabled
            v-model="ruleForm.startTime"
            type="date"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="overTime">
          <el-date-picker
            :start-placeholder="ruleForm.startTime + ''"
            v-model="ruleForm.overTime"
            type="date"
            :picker-options="pickerOptions"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="roleType === 10" label="是否上传机构" prop="uploading">
          <el-radio-group v-model="ruleForm.uploading">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
          <el-tag size="mini" style="margin-left: 30px" @click="messageAlert">?</el-tag>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" maxlength="100" resize="none" rows="3" v-model="ruleForm.remark" placeholder="请填写备注信息"></el-input>
        </el-form-item>
        <!-- <FormButtonGroup @saveData="submitForm('ruleForm')" save-text="保存" fullsplan="fullsModifyplan"></FormButtonGroup> -->
        <!-- <el-form-item class="button-section" style="text-align: right; justify-content: flex-end">
          <el-button type="primary" @click="submitForm('ruleForm')" v-loading.fullscreen.lock="fullsModifyplan">
            {{ saveText }}
          </el-button>
          <el-button @click="back">
            {{ cancelText }}
          </el-button>
        </el-form-item> -->
        <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')" v-loading.fullscreen.lock="fullsModifyplan">保存</el-button>
        <el-button @click="back">取消</el-button>
      </el-form-item>
      </el-form>

    </div>
  </div>
</template>

<script>
import screeningService from '@/globals/service/detection/screening.js'
// import schoolService from '@/globals/service/detection/school.js'
import selectService from '@/globals/service/detection/select.js'
import AdministratorService from '@/globals/service/management/Administrator.js'
import storage from '@/globals/storage/index.js'
import SysService from '@/globals/service/detection/Sys.js'
import organizationService from '@/globals/service/management/organization.js'
import { Loading } from 'element-ui'
export default {

  data () {
    return {
      loading: true,
      getGradeeClassOptions: [],
      getGradeClass: {
        lazy: true,
        checkStrictly: true,
        multiple: true,
        lazyLoad: (node, resolve) => {
          const { level } = node
          let data
          // const schoolId = storage.get('schoolId')
          setTimeout(() => {
            // if (node.children && node.children.length > 0) {
            //   return resolve(data)
            // }
            // if (level === 1) {
            //   selectService.class({ gradeId: node.value }).then(res => {
            //     data = res.data.map(item => {
            //       return {
            //         value: item.id,
            //         label: item.className,
            //         leaf: true
            //       }
            //     })
            //     if (data.length === 0) {
            //       this.props.checkStrictly = true
            //       resolve(false)
            //       return
            //     }
            //     resolve(data)
            //   })
            //   // return
            // }
            // // selectService.grade({ schoolId: schoolId }).then(async res => {
            // //   data = res.data.map(item => {
            // //     return {
            // //       value: item.id,
            // //       label: item.gradeName,
            // //       leaf: false
            // //     }
            // //   })
            // //   await Promise.all(data.map(async item => {
            // //     let ClassList
            // //     const zhiS = this.ruleForm.greadSchoolClassSelect.some(itemE => item.value === itemE[0] && itemE[1] != null)
            // //     if (zhiS) {
            // //       await selectService.class({ gradeId: item.id }).then(res => {
            // //         ClassList = res.data.map(item => {
            // //           return {
            // //             value: item.id,
            // //             label: item.className,
            // //             leaf: true
            // //           }
            // //         })
            // //       })
            // //       item.children = ClassList
            // //     }
            // //   }))
            // //   if (data.length === 0) {
            // //     // this.props.checkStrictly = true
            // //     resolve([])
            // //     return
            // //   }
            // return resolve(data)
            // })

            // 已经有节点不再请求
            if (node.children && node.children.length > 0) {
              return resolve(data)
            }
            if (level === 2) {
              // 当前是年级的，查询下级班级
              console.log('查询年级' + node.label + '的班级')
              selectService.class({ gradeId: node.value }).then(res => {
                // console.log('res1', res)
                data = res.data.map(item => {
                  return {
                    value: item.id,
                    label: item.className,
                    leaf: true
                  }
                })
                // console.log('data1', data)
                resolve(data)
              })
              return
            }
            if (level === 1) {
              // 当前层级是学校的，请求查询年级
              console.log('查询学校' + node.label + '的年级')
              selectService.grade({ schoolId: node.value + '' }).then(async res => {
                // console.log('res2', res)
                data = res.data.map(item => {
                  return {
                    value: item.id,
                    label: item.gradeName,
                    leaf: false
                  }
                })
                // console.log('data2', data)
                return resolve(data)
              })
              return
            }

            // 没有的时候查询所有学校
            selectService.school({ startPage: 1, pageSize: 1000 }).then(async res => {
              // console.log('res4', res)
              console.log('没有的时候查询所有学校')
              const schoolId = storage.get('schoolId')
              if (schoolId) {
              // data = res.data.schoolList.filter(item => item.id === schoolId)
                data = res.data.schoolList
              } else {
                data = res.data.schoolList
              }
              data = data.map(item => {
                return {
                  value: item.id,
                  label: item.schoolName,
                  leaf: false
                }
              })
              // console.log('123', data)

              await Promise.all(data.map(async item => {
                const zhi = this.ruleForm.greadSchoolClassSelect.some(items => item.value === items[0] && items[1] != null)
                if (zhi) {
                  // 选择了学校，并且选择了年级
                  await selectService.grade({ schoolId: item.value + '' }).then(async res => {
                    // console.log('res5', res)
                    item.children = await Promise.all(res.data.map(async itemS => {
                      const zhiS = this.ruleForm.greadSchoolClassSelect.some(itemE => itemS.id === itemE[1] && itemE[2] != null)
                      let ClassList = []
                      if (zhiS) {
                        // 选择了年级且选择了班级
                        await selectService.class({ gradeId: itemS.id }).then(res => {
                          // console.log('res6', res)
                          ClassList = res.data.map(item => {
                            return {
                              value: item.id,
                              label: item.className,
                              leaf: true
                            }
                          })
                        })
                      }
                      const greadData = {
                        value: itemS.id,
                        label: itemS.gradeName,
                        leaf: false
                      }
                      if (zhiS) {
                        greadData.children = ClassList
                      }
                      return greadData
                    }))
                  }
                  )
                }
              }))
              // console.log('data3', data)
              this.options = data
              this.loadingInstance().close()
              return resolve(data)
            })
          }, 1000)
        }
      },
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < this.ruleForm.startTime
        }
      },
      options: [],
      props: {
        lazy: true,
        checkStrictly: true,
        multiple: true,
        lazyLoad: (node, resolve) => {
          const { level } = node
          let data
          setTimeout(() => {
            // 已经有节点不再请求
            if (node.children && node.children.length > 0) {
              return resolve(data)
            }
            if (level === 2) {
              // 当前是年级的，查询下级班级
              console.log('查询年级' + node.label + '的班级')
              selectService.class({ gradeId: node.value }).then(res => {
                data = res.data.map(item => {
                  return {
                    value: item.id,
                    label: item.className,
                    leaf: true
                  }
                })
                resolve(data)
              })
              return
            }
            if (level === 1) {
              // 当前层级是学校的，请求查询年级
              console.log('查询学校' + node.label + '的年级')
              selectService.grade({ schoolId: node.value + '' }).then(async res => {
                data = res.data.map(item => {
                  return {
                    value: item.id,
                    label: item.gradeName,
                    leaf: false
                  }
                })
                return resolve(data)
              })
              return
            }

            // 没有的时候查询所有学校
            selectService.school({ startPage: 1, pageSize: 1000 }).then(async res => {
              console.log('没有的时候查询所有学校')
              const schoolId = storage.get('schoolId')
              if (schoolId) {
                data = res.data.schoolList.filter(item => item.id === schoolId)
              } else {
                data = res.data.schoolList
              }
              data = data.map(item => {
                return {
                  value: item.id,
                  label: item.schoolName,
                  leaf: false
                }
              })

              await Promise.all(data.map(async item => {
                const zhi = this.ruleForm.greadSchoolClassSelect.some(items => item.value === items[0] && items[1] != null)
                if (zhi) {
                  // 选择了学校，并且选择了年级
                  await selectService.grade({ schoolId: item.value + '' }).then(async res => {
                    item.children = await Promise.all(res.data.map(async itemS => {
                      const zhiS = this.ruleForm.greadSchoolClassSelect.some(itemE => itemS.id === itemE[1] && itemE[2] != null)
                      let ClassList = []
                      if (zhiS) {
                        // 选择了年级且选择了班级
                        await selectService.class({ gradeId: itemS.id }).then(res => {
                          ClassList = res.data.map(item => {
                            return {
                              value: item.id,
                              label: item.className,
                              leaf: true
                            }
                          })
                        })
                      }
                      const greadData = {
                        value: itemS.id,
                        label: itemS.gradeName,
                        leaf: false
                      }
                      if (zhiS) {
                        greadData.children = ClassList
                      }
                      return greadData
                    }))
                  }
                  )
                }
              }))
              this.options = data
              this.loadingInstance().close()
              return resolve(data)
            })
          }, 1000)
        }
      },
      // props: { multiple: true },
      schoolGreadClass: [],
      teacherListData: [],
      fullsModifyplan: false, // 加载开关
      fullscreenLoading: false,
      startPage: 1,
      pageSize: 100,
      // schoolOptions: [],
      roleType: null,
      ruleForm: {
        screeningName: '',
        remark: '',
        // 选中的学校、年级、班级
        greadSchoolClassSelect: null,
        // 筛查员Id
        sysList: [],
        // 日期
        startTime: null,
        overTime: null,
        // 是否上传机构
        uploading: 0
      },
      rules: {
        screeningName: [
          { required: true, message: '请填写计划名称', trigger: 'blur' },
          { min: 1, max: 30, message: '长度在 1 - 30 个字符内', trigger: 'blur' }
        ],
        remark: [
          { required: false, message: '请填写备注信息', trigger: 'blur' },
          { min: 0, max: 100, message: '长度在 100 个字符内', trigger: 'blur' }
        ],
        greadSchoolClassSelect: [
          { required: false, message: '请选择学校年级班级', trigger: 'blur' }
        ],
        uploading: [
          { required: true, message: '请选择是否上传机构', trigger: 'change' }
        ],
        sysList: [
          { required: true, message: '请选择筛查员', trigger: 'change' }
        ],
        overTime: [
          { required: true, message: '请选择结束时间', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    this.roleType = Number(storage.get('roleType')) || null
    // this.loadingInstance()
    this.getTeacher()
    const row = this.$route.query
    this.ruleForm = {
      id: row.id,
      screeningName: row.screeningName,
      remark: row.remark,
      overTime: row.overTime ? Number(row.overTime) : null,
      startTime: row.startTime ? Number(row.startTime) : null,
      uploading: Number(row.isUpload)
    }
    // console.log('row: ', row)
    // 获取当前数据选中的学校和筛查员
    organizationService.getSchoolAndUsers({ id: row.id }).then(async res => {
      // console.log('resw', res)
      // 选中的学校
      this.ruleForm.greadSchoolClassSelect = res.data.screeningVoList.map(item => {
        const data = []
        // if (this.roleType === 9 && item.schoolId) data.push(item.schoolId)
        if (item.schoolId) data.push(item.schoolId)
        if (item.gradeId) data.push(item.gradeId)
        if (item.classId) data.push(item.classId)
        return data
      })
      // let data = []
      // await Promise.all(this.ruleForm.greadSchoolClassSelect.map(async item => {
      //   await selectService.school({ startPage: 1, pageSize: 1000 }).then(res => {
      //     const schoolId = storage.get('schoolId')
      //     if (schoolId) {
      //       data = res.data.schoolList.filter(item => item.id === schoolId)
      //     } else {
      //       data = res.data.schoolList
      //     }
      //     data = data.map(item => {
      //       return {
      //         value: item.id,
      //         label: item.schoolName,
      //         leaf: false
      //       }
      //     })
      //   })
      // }))
      // 机构获取学校年级班级
      if (Number(this.roleType) === 9) {
        // await selectService.school({ startPage: 1, pageSize: 1000 }).then(res => {
        //   const schoolId = storage.get('schoolId')
        //   if (schoolId) {
        //     data = res.data.schoolList.filter(item => item.id === schoolId)
        //   } else {
        //     data = res.data.schoolList
        //   }
        //   data = data.map(item => {
        //     return {
        //       value: item.id,
        //       label: item.schoolName,
        //       leaf: false
        //     }
        //   })
        // })
        // await Promise.all(data.map(async item => {
        //   const zhi = this.ruleForm.greadSchoolClassSelect.some(items => item.value === items[0] && items[1] != null)
        //   if (zhi) {
        //     await selectService.grade({ schoolId: item.value + '' }).then(async res => {
        //       item.children = await Promise.all(res.data.map(async itemS => {
        //         const zhiS = this.ruleForm.greadSchoolClassSelect.some(itemE => itemS.id === itemE[1] && itemE[2] != null)
        //         let ClassList = []
        //         if (zhiS) {
        //           await selectService.class({ gradeId: itemS.id }).then(res => {
        //             ClassList = res.data.map(item => {
        //               return {
        //                 value: item.id,
        //                 label: item.className,
        //                 leaf: true
        //               }
        //             })
        //           })
        //         }
        //         const greadData = {
        //           value: itemS.id,
        //           label: itemS.gradeName,
        //           leaf: false
        //         }
        //         if (zhiS) {
        //           greadData.children = ClassList
        //         }
        //         return greadData
        //       }))
        //     }
        //     )
        //     this.options = data
        //     this.loadingInstance().close()
        //   }
        // }))
      }
      // 学校获取年级班级
      if (Number(this.roleType) === 10) {
        // const schoolId = storage.get('schoolId')
        // await selectService.grade({ schoolId: schoolId + '' }).then(async res => {
        //   data = await Promise.all(res.data.map(async itemS => {
        //     const zhiS = this.ruleForm.greadSchoolClassSelect.some(itemE => itemS.id === itemE[0] && itemE[1] != null)
        //     let ClassList = []
        //     if (zhiS) {
        //       await selectService.class({ gradeId: itemS.id }).then(res => {
        //         ClassList = res.data.map(item => {
        //           return {
        //             value: item.id,
        //             label: item.className,
        //             leaf: true
        //           }
        //         })
        //       })
        //     }
        //     const greadData = {
        //       value: itemS.id,
        //       label: itemS.gradeName,
        //       leaf: false
        //     }
        //     if (zhiS) {
        //       greadData.children = ClassList
        //     }
        //     return greadData
        //   }))
        // })
        // this.getGradeeClassOptions = data
        // this.loadingInstance().close()
      }
      // this.options = Promise.all(this.ruleForm.greadSchoolClassSelect.map(item => {
      //   selectService.school({ startPage: 1, pageSize: 1000 }).then(res => {
      //     const schoolId = storage.get('schoolId')
      //     let data = []
      //     if (schoolId) {
      //       data = res.data.schoolList.filter(item => item.id === schoolId)
      //     } else {
      //       data = res.data.schoolList
      //     }
      //     data = data.map(item => {
      //       return {
      //         value: item.id,
      //         label: item.schoolName,
      //         leaf: false
      //       }
      //     })
      //   })
      //   // if (item.length <= 2) {
      //   //   data.forEach(item => {
      //   //     if (data.value === item.)
      //   //   })
      //   // }
      // }))

      // 选中的筛查员
      this.ruleForm.sysList = res.data.userRespList.map(item => {
        return Number(item.id)
      })
    })

    this.loading = false
  },
  mounted () {
    // this.roleType = sessionStorage.getItem('roleType') || null
  },
  methods: {
    getDreadClassData () {
      this.$forceUpdate()
    },
    messageAlert () {
      this.$notify({
        title: '提示',
        message: '选择上报后将筛查计划和筛查数据同步到合作机构',
        duration: 10000
      })
    },
    // fullsplan (fullsModifyplan) {
    //   this.fullsModifyplan = fullsModifyplan
    // },
    loadingInstance () {
      return Loading.service()
    },
    back () {
      this.$router.back()
    },
    submitForm (formName) {
      // console.log(this.$refs[formName])
      if (this.ruleForm.greadSchoolClassSelect.length === 0) {
        return this.$message({ type: 'info', message: '请选择学校年级班级' })
      }
      // this.fullsModifyplan = true
      this.$refs[formName].validate((valid) => {
        // console.log(valid)
        if (valid) {
          this.fullscreenLoading = true
          const ruleForm = this.ruleForm
          ruleForm.schoolIdList = []
          ruleForm.gradeId = []
          ruleForm.classId = []
          const selectList = ruleForm.greadSchoolClassSelect.filter(item => item.length === 1)
          // ruleForm.greadSchoolClassSelect.map(item => {
          //   if (item.length === 1) {
          //     ruleForm.schoolIdList.push(item[0])
          //     ruleForm.gradeId.push(null)
          //     ruleForm.classId.push(null)
          //   }
          //   if (selectList.some(data => data[0] === item[0])) return
          //   if (item.length === 2) {
          //     ruleForm.schoolIdList.push(item[0])
          //     ruleForm.gradeId.push(item[1])
          //     ruleForm.classId.push(null)
          //   }
          //   if (item.length === 3) {
          //     ruleForm.schoolIdList.push(item[0])
          //     ruleForm.gradeId.push(item[1])
          //     ruleForm.classId.push(item[2])
          //   }
          // })
          if (this.roleType === 9) {
            ruleForm.greadSchoolClassSelect.forEach(item => {
              if (item.length === 1) {
                ruleForm.schoolIdList.push(item[0])
                ruleForm.gradeId.push(null)
                ruleForm.classId.push(null)
                return
              }
              if (selectList.some(data => data[0] === item[0])) return
              if (item.length === 2) {
                ruleForm.schoolIdList.push(item[0])
                ruleForm.gradeId.push(item[1])
                ruleForm.classId.push(null)
                return
              }
              if (item.length === 3) {
                ruleForm.schoolIdList.push(item[0])
                ruleForm.gradeId.push(item[1])
                ruleForm.classId.push(item[2])
              }
            }
            )
          }
          if (this.roleType === 10) {
            // const schoolId = storage.get('schoolId')
            // ruleForm.greadSchoolClassSelect.forEach(item => {
            //   if (item.length === 1) {
            //     ruleForm.schoolIdList.push(schoolId)
            //     ruleForm.gradeId.push(item[0])
            //     ruleForm.classId.push(null)
            //     return
            //   }
            //   if (selectList.some(data => data[0] === item[0])) return
            //   if (item.length === 2) {
            //     ruleForm.schoolIdList.push(schoolId)
            //     ruleForm.gradeId.push(item[0])
            //     ruleForm.classId.push(item[1])
            //   }
            // }
            // )
            ruleForm.greadSchoolClassSelect.forEach(item => {
              if (item.length === 1) {
                ruleForm.schoolIdList.push(item[0])
                ruleForm.gradeId.push(null)
                ruleForm.classId.push(null)
                return
              }
              if (selectList.some(data => data[0] === item[0])) return
              if (item.length === 2) {
                ruleForm.schoolIdList.push(item[0])
                ruleForm.gradeId.push(item[1])
                ruleForm.classId.push(null)
                return
              }
              if (item.length === 3) {
                ruleForm.schoolIdList.push(item[0])
                ruleForm.gradeId.push(item[1])
                ruleForm.classId.push(item[2])
              }
            }
            )
          }
          this.fullsModifyplan = true

          ruleForm.startTime = new Date(ruleForm.startTime).getTime()
          ruleForm.overTime = new Date(ruleForm.overTime).getTime()
          screeningService.update(ruleForm).then(res => {
            this.$message({
              type: 'success',
              message: '编辑成功!'
            })
            this.$router.push({ name: 'detectionScreeningPlanList' })
            this.fullscreenLoading = false
            this.fullsModifyplan = false
          }).catch(() => {
            this.fullscreenLoading = false
            this.fullsModifyplan = false
          })
        }
      })
    },
    // 获取学校老师或者筛查员
    getTeacher () {
      const schoolId = storage.get('schoolId')
      if (schoolId) {
        // 获取学校的所有老师
        AdministratorService.AdministratorGetTeacherList({ schoolId: schoolId, pageSize: 50000, startPage: 1 }).then(res => {
          this.teacherListData = res.data.teacherList.map(item => {
            return {
              label: item.teacherName,
              value: item.teacherId,
              phone: item.phone

            }
          })
        })
      } else {
        // 获取所有的筛查员
        SysService.search({ roleType: 11 }).then(res => {
          this.teacherListData = res.data.map(item => {
            return {
              label: item.sysName !== null ? item.sysName : '筛查员',
              value: item.id,
              phone: item.phone
            }
          })
        })
      }
    },
    getClassSelect () {
      this.ruleForm.classId = null
      this.classOptions = []
      selectService.class({ gradeId: this.ruleForm.gradeId + '' }).then(res => {
        const classList = res.data
        const classOptions = []
        classList.forEach(item => {
          classOptions.push({
            value: item.id,
            label: item.className
          })
        })
        this.classOptions = classOptions
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.header-container{
  width: 100%;
  height: 43px;
  border-bottom: 1px solid #f5f5f5;
  .header-section{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .route-name{
      line-height: 43px;
      font-size: 12px;
      color: #333;
    }
    .button-section{
      display: flex;
      align-items: center;
    }
  }
}
  .form-main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 24px 0;
    box-sizing: border-box;
    max-width: 600px;
  }
  .page{
    padding: 20px 10px;
    background-color: #fff;
    min-height: 100%;
  }
  .red{
    color: #f50e0e;
  }
  .from-foot{
    margin-left: 30px;
    .el-button{
      margin-right: 10px;
    }
  }
</style>

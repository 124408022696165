import axios from './../../request/axios.js'
import api from './../../request/api.js'

const screeningService = {
  add: function (params) {
    return axios.post(api.screening.add, params)
  },
  update: function (params) {
    return axios.post(api.screening.update, params)
  },
  list: function (params) {
    return axios.post(api.screening.list, params)
  },
  del: function (params) {
    return axios.post(api.screening.del, params)
  },
  delPlan: function (params) {
    return axios.post(api.screening.delPlan, params)
  },
  search: function (params) {
    return axios.post(api.screening.search, params)
  },
  ScreenedVo: function (params) {
    return axios.post(api.screening.ScreenedVo, params)
  },
  screened: function (params) {
    return axios.post(api.screening.screened, params)
  },
  qrcode: function (params) {
    return axios.post(api.screening.qrcode, params)
  }
}
export default screeningService

import axios from './../../request/axios.js'
import api from './../../request/api.js'

const teacherService = {
  // 根据筛查计划获取学校服务对象
  getSchoolAndUsers: function (params) {
    return axios.post(api.management.organization.getSchoolAndUsers, params)
  },
  // 获取未过期的筛查计划
  getNotOutDateScreeningList: function (params) {
    return axios.post(api.management.organization.getNotOutDateScreeningList, params)
  }
}
export default teacherService

import axios from './../../request/axios.js'
import api from './../../request/api.js'
const selectService = {
  screening: function (params) {
    return axios.post(api.select.screening, params)
  },
  class: function (params) {
    return axios.post(api.select.class, params)
  },
  grade: function (params) {
    return axios.post(api.select.grade, params)
  },
  school: function (params) {
    return axios.post(api.select.school, params)
  }
}
export default selectService

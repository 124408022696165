import axios from './../../request/axios.js'
import api from './../../request/api.js'

const SysService = {
  add: function (params) {
    return axios.post(api.Sys.add, params)
  },
  update: function (params) {
    return axios.post(api.Sys.update, params)
  },
  list: function (params) {
    return axios.post(api.Sys.list, params)
  },
  del: function (params) {
    return axios.post(api.Sys.del, params)
  },
  search: function (params) {
    return axios.post(api.Sys.search, params)
  },
  type: function (params) {
    return axios.post(api.Sys.type, params)
  }
}
export default SysService
